.contMensajeCentro {
    height: 100%;
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
}

.contMensajeCentro>:first-child {
    color: gray;
}