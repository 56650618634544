.contPrincipal {
    margin-top: 10px;
    height: 72vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.contBtnAnadir {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 32px;
}

.contMensajeVacio {
    margin: 30px 0;
}