.contListas {
    display: grid;
    grid-template-columns: 250px 1fr;
    height: 100%;
}

.contListas>:first-child {
    border-right: 1px solid lightgray;
    height: 100%;
    padding-right: 10px;
    margin-right: 10px;
}

.contItemLista {
    padding: 10px;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    margin-top: 10px;
}

.contItemLista:hover {
    background-color: var(--color-principal);
    color: white;
}

.contItemLista.active {
    background-color: var(--color-principal);
    color: white;
}

.tituloLista {
    margin-bottom: 10px;
}