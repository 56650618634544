.contLista {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
}

.itemLista {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 10px;
    background-color: white;
    padding: 10px;
    align-items: center;
    margin-bottom: 4px;
    border-radius: 10px;
    padding-left: 15px;
    height: 52px;
}

.contListas {
    height: 100%;
    overflow: scroll;
    margin-right: -15px;
}