.cont-inicio-configuracion {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cont-inicio-configuracion .cont-seccion {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.configuracion-aplicaciones-seccion {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;
}

.configuracion-seccion {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}