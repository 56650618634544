.cont-form-usuario {
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cont-form-usuario>form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-usuario-acciones {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.form-usuario-toggle {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  width: 100px;
  align-self: center;
}