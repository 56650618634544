.cont-equipo-historial-certificado {
  display: grid;
  grid-template-columns: max-content 1fr max-content max-content;
  padding: 10px 0;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-right: 20px;
}

.cont-equipo-historial-certificado :nth-child(3) {
  justify-self: flex-end;
}

.equipo-historial-certificado-esta-orden {
  font-size: 0.85rem;
  color: var(--color-texto-lowest);
}