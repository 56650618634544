.cont-calibraciones {
    display: grid;
    grid-template-columns: 280px 1fr;
    height: calc(100vh - 60px);
    overflow: hidden;
}

.calibraciones-lista-equipos {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-right: 1px solid lightgray;
    height: 100%;
}

.calibraciones-equipo-lista {
    display: flex;
    padding: 15px;
    cursor: pointer;
    align-items: center;
    font-size: 0.95rem;
}

.calibraciones-equipo-lista:hover {
    background-color: var(--color-secundario);
    color: var(--color-texto-active);
}

.calibraciones-equipo-lista.active {
    background-color: var(--color-principal);
    color: var(--color-texto-active);
}

.cont-hoja-datos1 {
    background-color: white;
    padding: var(--padding-card);
}