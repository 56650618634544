.rowHorizontal {
    display: grid;
    grid-template-columns: 1fr 140px 40px 40px;
    gap: 15px;
    padding: 5px 10px;
    align-items: center;
}

.rowHorizontal:last-child {
    border-radius: 0 0 10px 10px;
}

.equipos {
    font-size: 0.9rem;
    color: rgb(70, 70, 70);
    background-color: rgb(250, 250, 250);
}

.tipoEquipo {
    margin-top: 10px;
    background-color: white;
    padding: 10px;
    font-weight: 500;
    /* box-shadow: 0px 2px 3px 1px rgb(230, 230, 230); */
    font-weight: 600;
    color: var(--color-trullo);
    text-align: center;
}

.mensajeCentro {
    margin: 10px 0px;
}

.contPrincipal {
    min-height: 42px;
    max-height: 440px;
    overflow-x: hidden;
    overflow-y: auto;
}

.contTituloBtn {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    /* justify-content: flex-end; */
    /* margin-top: 1px; */
}

.tituloRow {
    color: var(--color-trullo);
    background-color: white;
    margin-top: 10px;
    border-radius: 10px 10px 0 0;
}