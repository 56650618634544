.form {
    display: grid;
    grid-template-columns: 60px 1fr 1fr 1fr;
    width: 600px;
    gap: 10px;
}

.form>div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.form>div:first-child {
    gap: 20px;
}

.form span {
    color: var(--color-texto-low);
    font-size: 0.9rem;
}

.form>div>p {
    font-size: 0.9rem;
}

.cont {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cont>div {
    display: grid;
    grid-template-columns: 1fr max-content;
    width: 100%;
    align-items: center;
    margin: 10px;
    max-width: 600px;
}

.formFC {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 600px;
    gap: 10px;
}