.cont-listas-formatos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 10px;
}

.cont-listas-formatos>div>h3 {
    margin-bottom: 10px;
}

.fila-formatos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--color-texto-low);
    border-radius: 10px;
}

.tabla-formatos {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}