.cont-detalle-ot {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cont-contenido-detalle {
    display: flex;
    flex-direction: column;
    padding: 0;
    /* padding-bottom: 15px; */
    gap: 10px;
}

/* .cont-contenido-detalle>div {
    margin: 10px;
} */

.detalle-cont-btns {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}

.titulo-accion {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.btns-cont-contenido-detalle {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    grid-column: 1/-1;
}

.cont-detalle-row {
    grid-column: 1/-1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cont-detalle-fechas {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}