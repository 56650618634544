.cont-card-cliente {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.cliente-datos-principales {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.cliente-datos-principales>p:first-child {
    font-size: 1em;
    /* font-weight: bold; */
    overflow: hidden;
    margin-bottom: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--color-principal);
    font-weight: 600;
}

.cliente-datos-principales>p:last-child {
    margin-bottom: 15px;
    font-size: 0.85em;
    /* font-weight: bold; */
    color: var(--color-texto-low)
}

.cliente-datos-detalles {
    display: flex;
    flex-direction: column;
    gap: 15px;
    filter: invert(0.55);
    overflow: hidden;
}

.cliente-datos-detalles>div {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 7px;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cliente-clicker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}