.cont-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
}

.cont-input:hover .cont-input-ico {
  box-shadow: var(--borde-input);
}

.cont-input > p {
  color: var(--color-texto-lowest);
  font-size: 0.9em;
  margin-left: var(--radio-input);
  margin-bottom: 3px;
}

.cont-input > div {
  background-color: white;
  /* background-color: var(--color-fondo-input); */
}

.cont-input-ico {
  display: grid;
  grid-template-columns: max-content 1fr;
  height: calc(var(--radio-input) * 4);
  align-items: center;
  /* box-shadow: var(--borde-input); */
  border-radius: var(--radio-input);
  transition: box-shadow var(--transicion-extra-rapida),
    background-color var(--transicion-rapida);
}

.textArea .cont-input-ico {
  height: max-content;
  min-height: calc(var(--radio-input) * 4);
  padding: 4px;
}

.cont-input-ico:focus-within {
  box-shadow: var(--borde-input-focus);
  background-color: white !important;
}

.cont-input-ico > .input-content {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  height: 100%;
}

.relleno-input-no-ico {
  width: 5px;
}
