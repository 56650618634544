.cont-detalle-cliente {
  display: grid;
  grid-template-rows: 30px 1fr;
  gap: 10px;
  height: 100%;
}

.encabezado-detalle-cliente {
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: 10px;
}