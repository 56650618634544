.cont-clientes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cont-contenido-clientes {
  display: grid;
  grid-template-columns: repeat(auto-fit, 340px);
  /* grid-auto-rows: 195px; */
  /* height: calc(100vh - 125px); */
  justify-items: center;
  justify-content: center;
  gap: 30px;
  padding-top: 0px;
  /* margin-top: 20px; */
  /* padding: 10px 0; */
  /* overflow-y: auto; */
}

.example-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.example-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.example-exit {
  opacity: 1;
  transform: translateX(0);
}

.example-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}
