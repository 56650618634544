.contPrincipal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  width: max-content;
  margin: 0 auto;
}

.contPrincipal.nivel1 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  padding: 3px;
  gap: 3px;
  box-shadow: 0px 0px 1px 1px rgb(240, 240, 240);
}

.contPrincipal.nivel2 {
  gap: 8px;
}

.contPrincipal li {
  padding: var(--padding-tabs) calc(var(--padding-tabs));
  padding-bottom: 6px;
  color: var(--color-texto-low);
  font-size: 0.8em;
}

.contPrincipal.nivel1 li {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
}

.contPrincipal.nivel2 li {
  padding-bottom: 8px;
}

.contPrincipal.nivel1 li.active {
  background-color: var(--color-principal);
  color: white;
  box-shadow: inset 0px -2px 0px 0px var(--color-principal);
}

.contPrincipal.nivel2 li.active {
  color: var(--color-principal);
  box-shadow: inset 0px -2px 0px 0px var(--color-principal);
}

.contPrincipal.nivel1 li:hover {
  background-color: var(--color-secundario);
  color: white;
  cursor: pointer;
  box-shadow: inset 0px -2px 0px 0px var(--color-secundario);
}

.contPrincipal li:hover {
  /* background-color : var(--color-secundario); */
  color: var(--color-secundario);
  cursor: pointer;
  box-shadow: inset 0px -2px 0px 0px var(--color-secundario);
}