.cont-form-nueva-orden {
    width: 100%;
    display: grid;
    grid-template-rows: max-content max-content 1fr;
    align-items: flex-start;
    padding: 2px;
}

.cotizacion-titulo-form {
    display: grid;
    grid-template-columns: 120px 1fr 120px;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-bottom: 10px;
}

.cotizacion-titulo-form> :nth-child(2) {
    justify-self: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
    border-bottom: 1px solid rgb(210, 210, 210);
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.cotizacion-titulo-form .cont-label-text {
    margin-top: 0;
}

/* .cotizacion-titulo-form span {
    color: var(--color-principal);
} */

.form-nueva-orden {
    display: grid;
    grid-template-rows: max-content;
    gap: 20px;
    height: 100%;
    overflow: hidden;
}

.orden-datos-generales {
    display: grid;
    grid-template-columns: 220px 1fr;
    gap: 40px;
    overflow-y: auto;
    width: 780px;
    padding: 2px;
    padding-bottom: 3px;
    margin: 0 auto;
}

.orden-datos-generales>div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.orden-datos-generales>div>div {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.orden-visita-tecnica {
    display: grid;
    grid-template-columns: 70px 1fr;
    gap: 10px;
}

.orden-contrato {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.orden-equipos {
    height: 100%;
    overflow: hidden;
    padding: 0px;
    display: grid;
    border-radius: 10px;
    gap: 2px;
}

.orden-equipos-encabezado {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-end;
}

.orden-equipos-encabezado>:first-child {
    align-self: flex-end;
    margin: unset;
}

.orden-equipos-lista {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.cont-seccion2-equipos {
    overflow: hidden;
    display: grid;
    grid-template-rows: 32px 1fr;
    gap: 10px;
    max-height: 74vh;
}

.titulo-seccion-nueva-orden:not(:first-child) {
    margin-top: 15px;
}

.titulo-seccion-nueva-orden {
    color: var(--color-principal);
}

.cont-inputs-descuento {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 10px;
    align-items: center;
    margin-top: 5px;
}

.cont-tipo-servicio {
    display: grid;
    grid-template-columns: 120px;
    align-items: center;
    gap: 10px;
}

.cont-tipo-servicio>:first-child {
    color: gray;
    font-size: 0.9rem;
}