.contLoader {
    margin: auto;
    display: grid;
    justify-content: center;
    align-items: center;
}

.fullScreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.loader {
    display: inline-block;
    filter: invert(0.5);
    height: 32px;
    width: 32px;
    justify-self: center;
    align-self: center;

}

.loader:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    /* margin: 8px; */
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: animacionLoader 1.2s linear infinite;
    box-sizing: border-box;
}

@keyframes animacionLoader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}