.cont-contenido-marcas {
    display: grid;
    grid-template-columns: repeat(auto-fit, 280px);
    gap: 10px;
    padding: 10px;
    justify-content: center;
}

.cont-marca {
    display: grid;
    grid-template-columns: 1fr max-content;
    background-color: white;
    padding: 15px;
    align-items: center;
    border-radius: 10px;
}

.cont-marca> :last-child {
    opacity: 0.3;
}