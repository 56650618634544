.contPrincipal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
}

.items {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.item {
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
}

.item:hover {
    box-shadow: inset var(--borde-input);
}

.selected {
    background-color: var(--color-principal);
    color: white;
}