.cont-fila-equipo-orden {
    height: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: 25px 50px 1fr 70px;
    gap: 0px 10px;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    border-top: 1px solid rgb(230, 230, 230);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

.cont-fila-equipo-orden.error {
    background-color: rgb(255, 211, 211);
}

.cont-fila-equipo-orden span,
.cont-fila-equipo-orden input,
.cont-fila-equipo-orden select {
    font-size: 0.8rem;
}

.cont-fila-equipo-orden select,
.cont-fila-equipo-orden input {
    height: 30px;
}

.orden-titulo-lista {
    background-color: rgb(255, 255, 255, 0.5);
    backdrop-filter: blur(8px);
    border-bottom: 1px solid rgb(230, 230, 230);
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 10;
    margin-bottom: 5px;
}

.orden-titulo-lista:hover {
    box-shadow: none;
}

.orden-titulo-lista span {
    font-weight: bold;
    color: var(--color-trullo);
}

.cont-fila-equipo-orden>input[type='checkbox'] {
    width: 20px;
}

.cont-fila-equipo-orden:hover {
    border-left: 4px solid var(--color-trullo);
    border-right: 4px solid var(--color-trullo);
}

.fila-orden-datos-equipo>span {
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
}

.fila-orden-datos-equipo-balanza {
    display: grid;
    grid-template-columns: 1fr 100px 110px 110px 110px 100px 90px 30px 1fr;
    gap: 10px;
}

.fila-orden-datos-equipo-balanza>span {
    overflow: hidden;
    text-overflow: ellipsis;
}

.fila-orden-datos-equipo-pesopatron {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 40px 1fr 1fr;
    gap: 5px;
}

.fila-orden-datos-equipo-termometro {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
}

.format-ico-borrar-equipo {
    padding: 8px;
    filter: invert(1);
    background-color: rgb(60, 60, 60);
    border-radius: 50%;
    cursor: pointer;
    width: 25px;
}

.format-ico-borrar-equipo:hover {
    background-color: rgb(50, 80, 80);
}

.cont-fila-equipo-balanza {
    grid-template-columns: 25px 50px 1fr 70px 40px;
}