.cont-menu-sesion {
  height: 35px;
  width: 35px;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  margin-left: 7.5px;
  margin-bottom: 7.5px;
  bottom: 0;
  left: 0;
  transition: height var(--transicion-rapida), width var(--transicion-rapida), border-radius var(--transicion-rapida);
  /* box-shadow: var(--borde-input); */
  z-index: 10;

}

.cont-menu-sesion.active {
  height: 140px;
  width: 270px;
  box-shadow: 0px 0px 30px 30px rgb(0, 0, 0, 0.2);
  background-color: var(--color-fondo-tab);
  border-radius: var(--radio-input);
}

.cont-menu-sesion .cont-contenido-menu-sesion,
.cont-menu-sesion .menu-sesion-lista {
  visibility: hidden;
}

.cont-menu-sesion.active .cont-contenido-menu-sesion,
.cont-menu-sesion.active .menu-sesion-lista {
  visibility: visible;
}

.cont-contenido-menu-sesion {
  display: grid;
  grid-template-columns: 60px 1fr;
  align-items: center;
  height: 60px;
  gap: 2px;
}

.cont-contenido-menu-sesion div:first-child {
  justify-self: center;
}

.cont-contenido-menu-sesion .datos-usuario :nth-child(2) {
  color: var(--color-texto-lowest);
  font-size: 0.9rem;
}

.menu-sesion-lista li {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-principal)
}

.menu-sesion-lista li:hover {
  background-color: var(--color-foco-links);
}

.menu-sesion-lista>li>span {
  margin-left: 10px;
}

.menu-sesion-flecha {
  position: absolute;
  height: 35px;
  width: 35px;
  padding: 5px;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
  background-color: #bb730d;
  color: white;
  font-size: 1.4rem;
  line-height: 25px;
  text-align: center;
}

.menu-sesion-flecha:hover {
  filter: brightness(1.1);
}