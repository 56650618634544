.contPrincipal {
    display: grid;
    grid-template-rows: 34px 1fr;
    height: 100%;
    gap: 10px;
}

.contInputsFechas {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px
}

.contInputsFechas>div:not(:nth-child(3)) {
    width: 180px;
}

.contLoader {
    width: 32px
}

.mensaje {
    color: red;
    font-size: 0.85rem;
    width: max-content;
    line-height: 32px;
    text-align: right;
}

.contIndicadoresActuales {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    justify-content: center;
}

.contIndicadoresActuales>div {
    margin-top: 3px;
    gap: 5px;
}

.encabezado {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    gap: 15px;
}