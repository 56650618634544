:root {
  /* colores */
  --color-fondo-app: rgb(245, 245, 255);
  --color-fondo-seccion: rgb(245, 245, 245);
  --color-foco-links: rgb(245, 245, 245);
  --color-fondo-modal: rgba(0, 0, 0, 0.25);
  --color-fondo-modalFS: rgba(235, 235, 235, 0.8);
  --color-fondo-card: rgb(255, 255, 255);
  --color-principal: rgb(29, 101, 195);
  --color-secundario: rgb(47, 135, 251);
  --color-texto-low: #585858;
  --color-texto-lowest: #999;
  --color-texto-active: white;
  --color-eliminar: rgb(206, 65, 65);
  --color-eliminar-hover: rgb(218, 71, 71);
  --color-fondo-focus: rgb(220, 220, 220);
  --color-fondo-button: rgb(255, 255, 255);
  /* --color-fondo-button: rgb(250, 250, 250);*/
  --color-fondo-input: rgb(248, 248, 248);
  /* --color-fondo-button: rgb(250, 250, 250); */
  /* --color-fondo-input: rgb(250,250,250); */
  --color-sombras: rgb(220, 220, 220);
  --color-error: rgb(180, 38, 38);
  --color-contenido-modal: rgba(250, 250, 250, 0.8);
  /* --color-contenido-modal: rgb(240, 240, 240); */
  --color-fondo-tab: rgb(255, 255, 255);
  --color-placeholder: rgb(200, 200, 200);
  --color-fondo-popup: rgb(240, 240, 240);
  --color-trullo: rgb(35, 155, 155);
  --color-trullo-hover: rgb(46, 176, 176);

  --color-calibracion: rgb(5, 158, 69);
  --color-mantenimiento: rgb(34, 89, 177);
  --color-ensayo: rgb(212, 153, 24);
  /* transiciones */
  --transicion-extra-rapida: 0.075s ease-in-out;
  --transicion-rapida: 0.15s ease-in-out;
  --transicion-moderada: 0.3s ease-in-out;
  --transicion-popup: 0.35s ease-in-out;

  /* Desenfoques */
  --desenfoque-principal: blur(20px);

  --padding-card: 20px;
  --padding-input: 5px;
  --padding-btn: 10px;
  --padding-contenido-modal: 25px;
  --padding-tabs: 5px;
  --padding-seccion: 10px;

  --sombra-card: 0px 30px 30px -15px rgba(0, 0, 0, 0.05);
  --sombra-contenedor-principal: 0px 10px 12px 5px rgba(0, 0, 0, 0.1);
  /* --sombra-elemento-primario:10px 10px 20px 4px rgba(0, 0, 0, 0.15); */
  --sombra-elemento-primario: 0px 10px 10px 1px rgba(0, 0, 0, 0.05);
  --sombra-navbar: 0px 0px 12px 0 var(--fondo);
  --sombra-popup: 0px 0px 50px 40px rgba(0, 0, 0, 0.15);
  --sombra-contenedor-sobrepuesto: 0px 0px 200px 40px rgba(0, 0, 0, 0.2);
  --sombra-seccion: 0px 30px 30px -30px rgba(0, 0, 0, 0.1);

  /* radios */
  --radio-seccion: 25px;
  --radio-card: 20px;
  --radio-tab: 15px;
  --radio-input: 8px;
  --radio-modal: 20px;
  --radio-popup: 20px;
  --radio-btn: 8px;

  /* bordes */
  --borde-input: 0 0 0 1px var(--color-sombras);
  --borde-input-focus: 0 0 0 2px var(--color-secundario);

  /* tiempos animaciones */
  --animacion-rapida: 0.15s;
  --animacion-media: 0.3s;
  --animacion-normal: 0.5s;
  --animacion-lenta: 1s;
}

@font-face {
  font-family: "Roboto";
  src: url("./media/fonts/Roboto/Roboto-Thin.ttf");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  src: url("./media/fonts/Roboto/Roboto-ThinItalic.ttf");
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  src: url("./media/fonts/Roboto/Roboto-Light.ttf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("./media/fonts/Roboto/Roboto-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("./media/fonts/Roboto/Roboto-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("./media/fonts/Roboto/Roboto-Italic.ttf");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("./media/fonts/Roboto/Roboto-Medium.ttf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("./media/fonts/Roboto/Roboto-MediumItalic.ttf");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("./media/fonts/Roboto/Roboto-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("./media/fonts/Roboto/Roboto-BoldItalic.ttf");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("./media/fonts/Roboto/Roboto-Black.ttf");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url("./media/fonts/Roboto/Roboto-BlackItalic.ttf");
  font-style: italic;
  font-weight: 900;
}


@font-face {
  font-family: "Poppins";
  src: url("./media/fonts/Poppins/Poppins-Thin.ttf");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url("./media/fonts/Poppins/Poppins-ThinItalic.ttf");
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url("./media/fonts/Poppins/Poppins-Light.ttf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("./media/fonts/Poppins/Poppins-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("./media/fonts/Poppins/Poppins-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("./media/fonts/Poppins/Poppins-Italic.ttf");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("./media/fonts/Poppins/Poppins-Medium.ttf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./media/fonts/Poppins/Poppins-MediumItalic.ttf");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./media/fonts/Poppins/Poppins-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("./media/fonts/Poppins/Poppins-BoldItalic.ttf");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("./media/fonts/Poppins/Poppins-Black.ttf");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Poppins";
  src: url("./media/fonts/Poppins/Poppins-BlackItalic.ttf");
  font-style: italic;
  font-weight: 900;
}






@font-face {
  font-family: "Quicksand";
  src: url("./media/fonts/Quicksand/static/Quicksand-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Quicksand";
  src: url("./media/fonts/Quicksand/static/Quicksand-Light.ttf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Quicksand";
  src: url("./media/fonts/Quicksand/static/Quicksand-Medium.ttf");
  font-style: noral;
  font-weight: 500;
}

@font-face {
  font-family: "Quicksand";
  src: url("./media/fonts/Quicksand/static/Quicksand-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Quicksand";
  src: url("./media/fonts/Quicksand/static/Quicksand-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
}




* {
  font-family: "Quicksand", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  background-color: var(--color-fondo-app);
}

a,
a:visited {
  text-decoration: none;
  color: black;
}

span>b {
  font-weight: bold;
}

input,
select {
  background-color: transparent;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: var(--padding-input);
  border-radius: var(--radio-input);
}

h3,
h4,
p,
span,
input,
div,
li,
button {
  font-weight: 500;

  /* font-weight: 300; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

input::placeholder {
  color: var(--color-placeholder);
}

.texto-validacion {
  width: 100%;
  grid-column: 1/-1;
  text-align: center;
  font-size: 0.8em;
  color: var(--color-error);
}