.cont-app {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 50px 1fr;
}

.cont-contenido-app {
  padding: 10px 15px;
  overflow-x: hidden;
  overflow-y: auto;
}

.cont-seccion {
  width: 100%;
  display: block;
  margin: 0 auto;
  background-color: var(--color-fondo-seccion);
  padding: var(--padding-seccion);
  border-radius: var(--radio-seccion);
  box-shadow: var(--sombra-seccion);
}

.cont-card {
  background-color: var(--color-fondo-card);
  border-radius: var(--radio-card);
  box-shadow: var(--sombra-card);
  padding: var(--padding-card);
}

.cont-2-columnas {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-full-width {
  grid-column: 1/-1;
}

.grid-gap-form {
  gap: 20px;
}

.cont-btns {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 10px;
}

.cont-acciones {
  position: absolute;
  top: 20px;
  right: 15px;
  left: auto;
  bottom: auto;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.cont-acciones .acciones-accion {
  display: grid;
  grid-template-rows: 30px max-content;
  gap: 2px;
  justify-items: center;
  cursor: pointer;
  width: 50px;
  overflow: hidden;
}

.cont-acciones .acciones-accion>p {
  font-size: 0.7rem;
  color: var(--color-texto-lowest);
  opacity: 0;
  transition: opacity var(--transicion-rapida);
}

.cont-card:hover .cont-acciones .acciones-accion>p {
  opacity: 1;
}

.cont-card:hover .cont-acciones .acciones-accion>div {
  opacity: 0.5;
}

.titulo-pagina {
  padding-left: 5px;
  border-left: 3px solid var(--color-principal);
}

.encabezado-nombre-barra-buscar {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  align-items: flex-start;
}

.encabezado-nombre-barra-buscar> :first-child {
  justify-self: flex-start;
  overflow: hidden;
}

.encabezado-nombre-barra-buscar> :nth-child(2) {
  justify-self: center;
}

.encabezado-nombre-barra-buscar> :nth-child(3) {
  justify-self: flex-end;
  margin-right: 0;
}

.input-border {
  box-shadow: 0px 0px 1px 1px lightgray;
  border-radius: 3px;
}

.input-border:focus {
  box-shadow: 0px 0px 0px 2px var(--color-principal);
}

.cont-loader-full-container {
  /* position: ; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  align-content: center;
  justify-content: center;
  grid-column: 1/-1;
  grid-row: 1/-1;
  display: grid;
  height: 100%;
}

.cont-circulo-estado {
  width: 10px;
  height: 10px;
  background-color: var(--color-fondo-input);
  border-radius: 50%;
}

/* colores estados */
.color-estado-inactivo {
  background-color: var(--color-fondo-input);
}

.color-estado-rojo {
  background-color: rgb(226, 0, 0);
}

.color-estado-verde {
  background-color: rgb(5, 158, 0);
}

/* colores estados */

.max-content {
  width: max-content;
}

.btn-atras-seccion::after {
  width: 140px;
  height: 40px;
  background-color: var(--color-fondo-app);
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
}

.btn-atras-seccion {
  position: absolute;
  top: 0;
  left: 0;
}

.btn-atras-seccion .btn {
  position: absolute;
  top: 4px;
  /* left: 140px; */
  left: 10px;
  z-index: 10;
}

.cont-btns-derecha {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  margin: 0 10px;
}


.cont-flex-vertical-gap {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cont-flex-horizontal-gap {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.cont-grid-equal-gap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  gap: 15px;
}

.oculto {
  opacity: 0;
}

.cont-full-width-center-content {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
}

.divisor {
  height: 2px;
  background-color: rgb(220, 220, 220);
  width: 100%;
  grid-column: 1/-1;
  /* grid-row: 1/-1; */
}