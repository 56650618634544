.tipoEquipo {
    margin-top: 10px;
    background-color: white;
    padding: 10px;
    font-weight: 500;
    /* box-shadow: 0px 2px 3px 1px rgb(230, 230, 230); */
}

.rowHorizontal {
    display: grid;
    grid-template-columns: 70px 1fr 40px;
    gap: 15px;
    padding: 5px 10px;
    align-items: center;
    border-left: 3px solid black;
}

.rowHorizontal :first-child {
    text-align: center;
}

.rowHorizontal:last-child {
    border-radius: 0 0 10px 10px;
}

.equipos {
    font-size: 0.9rem;
    color: rgb(70, 70, 70);
    background-color: rgb(250, 250, 250);
}

.calibracion {
    border-left-color: var(--color-calibracion);
}

.mantenimiento {
    border-left-color: var(--color-mantenimiento);
}

.ensayo {
    border-left-color: var(--color-ensayo);
}

.mensajeCentro {
    margin-top: 10px;
}