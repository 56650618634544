.cont-label-state>p:first-child {
  color: var(--color-texto-low);
  font-size: 0.85rem;
}

.cont-label-state {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin: 10px 0;
}

.format-ico-label-state {
  width: 20px;
  height: 20px;
}