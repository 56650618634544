.contIcoAyuda {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: blue;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 15px -2px black;
}

.icoAyuda {
    width: 25px;
    height: 25px;
    filter: invert(1);
}

.contFlotante {
    /* position: absolute; */
    /* bottom: 20px; */
    /* right: 20px; */
    width: 460px;
    /* height: max-content; */
    background-color: white;
    /* background-color: rgba(255, 255, 255, 0.5); */
    /* backdrop-filter: blur(40px); */
    /* box-shadow: 0px 5px 18px -5px gray, 0px 0px 1px 1px rgb(230, 230, 230); */
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 10px;
}

.titulo {
    margin-bottom: 10px;
}

.contFlotante>div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
}

.contFlotante>div>p {
    font-size: 0.8rem;
    color: rgb(50, 50, 50);
}

.contBarraEstados {
    margin: 0;
}