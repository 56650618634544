.cont-orden-trabajo {
    position: relative;
    padding-bottom: 0;
    overflow: hidden;
}

.cont-datos-resumen-orden-trabajo {
    display: grid;
    grid-template-columns: 1fr 80px;
    align-items: center;
}

.datos-resumen-orden-trabajo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px 0;
    margin-bottom: 20px;
}

.datos-resumen-orden-trabajo>div {
    display: flex;
    flex-direction: column;
}

.datos-resumen-orden-trabajo>div>span:first-child {
    font-size: 0.85rem;
    color: var(--color-texto-low);
}

.datos-resumen-orden-trabajo>.indicador-cant-equipos {
    grid-column: 2/3;
    grid-row: 1/-1;
    background-color: red;
}

.orden-num-orden {
    width: max-content;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.orden-num-orden>span {
    background-color: var(--color-fondo-app);
    font-weight: 600;
    padding: 6px;
    line-height: normal;
}

.orden-num-orden>span:first-child {
    border-radius: 15px 0px 0px 15px;
    padding-left: 14px;
    padding-right: 12px;
}

.orden-num-orden>span:last-child {
    border-radius: 0px 15px 15px 0px;
    padding-left: 12px;
    padding-right: 14px;
}

.cont-barra-estados {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: 1px;
    height: 6px;
    padding: 0;
    margin: 0 -20px;
}

.cont-barra-estados>div {
    background-color: var(--color-fondo-app);
}

.cont-barra-estados>.color-estado-completo {
    background-color: rgb(0, 233, 23);
}

.cont-barra-estados>.color-estado-0 {
    background-color: rgb(176, 160, 19);
}

.cont-barra-estados>.color-estado-1 {
    background-color: rgb(231, 219, 42);
}

.cont-barra-estados>.color-estado-2 {
    background-color: rgb(14, 156, 212);
}

.cont-barra-estados>.color-estado-3 {
    background-color: rgb(44, 232, 201);
}

.cont-barra-estados>.color-estado-4 {
    background-color: rgb(0, 187, 100);
}

.cont-barra-estados>.color-estado-5 {
    background-color: rgb(246, 126, 40);
}

.cont-barra-estados>.color-estado-6 {
    background-color: rgb(246, 126, 40);
}

.cont-barra-estados>.color-estado-7 {
    background-color: rgb(207, 0, 211);
}

.cont-barra-estados>.color-estado-8 {
    background-color: rgb(53, 209, 201);
}

.cont-barra-estados>.color-estado-error {
    background-color: rgb(243, 145, 145);
}

.orden-trabajo-clicker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rebeccapurple; */
    border-radius: 15px;
}

.orden-trabajo-indicador-servicio.calibracion {
    color: var(--color-calibracion)
}

.orden-trabajo-indicador-servicio.mantenimiento {
    color: var(--color-mantenimiento)
}

.orden-trabajo-indicador-servicio.ensayo {
    color: var(--color-ensayo)
}