.contPrincipal {
    margin-bottom: 5px;
    border-radius: 10px;
    overflow: hidden;
}

.contTitulo {
    padding: 10px;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    cursor: pointer;
}

.contTitulo:hover {
    background-color: rgb(250, 250, 250);
}

.titulo {
    font-weight: 500;
}

.contChildren {
    display: flex;
    flex-direction: column;
    background-color: rgb(240, 240, 240);
    overflow: hidden;
    max-height: 0;
    /* transform: scaleY(0);
    transform-origin: 50% 0; */
    transition: max-height var(--transicion-rapida);
}

.chevron {
    width: 20px;
    transition: transform var(--transicion-rapida);
    transform: rotate(180deg);
}

.chevron.active {
    width: 20px;
    transform: rotate(0deg);
}

.opened {
    max-height: none;
}