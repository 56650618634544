.contRichTextInput {
    background-color: rgb(250, 250, 250);
    border-radius: 10px;
    padding: 10px;
    max-height: 400px;
    overflow: auto;
    font-size: 0.9rem;
}

.contRichTextInput span {
    font-family: 'Courier New', Courier, monospace;
}

.strong>span {
    font-weight: bold;
}