.contPrincipal {
    display: flex;
    flex-direction: column;
}

.empresa {
    margin-top: 10px;
    padding: 10px;
    padding-top: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.1rem;
    border-left: 3px solid lightgray;
    align-items: center;
    color: var(--color-principal);
}

.contOrdenes {
    display: grid;
    grid-template-columns: repeat(auto-fit, 310px);
    padding: 20px;
    gap: 50px;
    justify-content: center;
    align-items: flex-start;
    border-left: 3px solid lightgray;
    margin-bottom: 40px;
}