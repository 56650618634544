.contPrincipal {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100%;
    width: 100%;
    border-radius: 15px;
}

.vacio {
    grid-template-rows: 1fr;
}