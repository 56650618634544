.cont-hoja-datos {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cont-hoja-datos>h3 {
    margin-top: 20px;
    padding-left: 10px;
}

.calibracion-cont-datos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.calibracion-2-column,
.calibracion-3-column {
    display: grid;
    gap: 20px;
}

.calibracion-3-column {
    grid-template-columns: 1fr 1fr 1fr;
}

.calibracion-2-column {
    grid-template-columns: 1fr 1fr;
}

.calibracion-2-column>div {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.cont-hoja1 h3 {
    color: rgb(0, 74, 159);
}

.calibracion-titulo-btn {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.separador-inferior {
    padding-top: 10px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px dotted lightgray;
}

.cont-hoja1 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 25px;
}

.titulo-lectura-excentricidad {
    text-align: center;
    border-bottom: 2px solid gray;
    padding-bottom: 5px;
    margin-top: 10px;
}

.titulo-lectura-excentricidad>h4 {
    color: var(--color-principal);
}

.titulo-lectura-excentricidad.lectura1 {
    grid-column: 2/4;
}

.titulo-lectura-excentricidad.lectura2 {
    grid-column: 4/6;
}

.table-row.titulo-excentricidad {
    padding-bottom: 0;
    margin-bottom: -5px;
}

.excentricidad div,
.excentricidad span {
    text-align: center;
}

.cont-frecuencia {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.divisor-top {
    border-top: 1px solid lightgray;
    margin-top: 15px;
    padding-top: 15px;
}

.servicio-calibracion {
    border-left: 3px solid var(--color-calibracion);
}

.servicio-mantenimiento {
    border-left: 3px solid var(--color-mantenimiento);
}

.center-hd {
    text-align: center;
}