.cont {
    width: 100%;
    position: relative;
}

.resultados {
    font-size: 0.8rem;
    margin: 0px 10px;
    margin-right: 35px;
    color: gray;
}

.btnBorrar {
    position: absolute;
    height: 22px;
    background-color: rgb(190, 190, 190);
    filter: invert(1);
    padding: 2px;
    border-radius: 50%;
    top: 5px;
    right: 6px;
}