.cont-usuarios {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* height: 100%; */
}

.cont-contenido-usuarios>div {
  display: grid;
  grid-template-columns: repeat(auto-fit, 280px);
  gap: 20px;
  /* height: 100%; */
  padding: var(--padding-seccion) 0;
  /* justify-content: center; */
}

/* .cont-contenido-usuarios>div:not(:last-child) {
  box-shadow: 0px 50px 10px -50px lightgray;
} */

.cont-contenido-usuarios .titulo-privilegios {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  opacity: 0.6;
}

.cont-contenido-usuarios h4:not(:first-child) {
  margin-top: 40px;
  /* padding-top: 30px; */
  /* border-top: 1px solid rgb(220, 220, 220); */
}