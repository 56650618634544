.cont-pesos {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cont-contenido-pesos {
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    justify-content: center;
    gap: 30px;
    padding: 0 10px;
}

.titulo-card-trazabilidad {
    font-size: 1rem;
    font-weight: 600;
}