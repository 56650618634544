.contFormato {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contFormatoFormulario {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 800px;
    min-width: 700px;
    gap: 20px;
}

.contFormatoFormulario>div>h3 {
    margin-left: 10px;
    margin-bottom: 10px;
}

.contCard {
    display: flex;
    flex-direction: column;
    gap: 15px;
}