.contPrincipal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 325px;
    gap: 15px;
}

.clientes {
    display: flex;
    flex-direction: row;
    background-color: var(--color-fondo-app);
    border-radius: 20px;
    width: 100%;
    height: max-content;
    padding: 10px;
    gap: 10px;
}

.cliente {
    background-color: white;
    width: 45px;
    height: 45px;
    border-radius: 15px;
}