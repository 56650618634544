.cont-usuario {
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 10px;
}

.borde-usuario-1 {
  /* border-left: 4px solid rgb(57, 143, 255); */
  /* background-color: rgb(57, 143, 255); */
  background-color: #ae7afb;
  /* box-shadow: 0px 0 0 1px rgb(57, 143, 255); */
}

.borde-usuario-2 {
  /* background-color: rgb(255, 112, 112); */
  background-color: #2c84bb;
  /* background-color: rgb(255, 207, 180); */
  /* box-shadow: 0px 0 0 1px rgb(255, 112, 112); */
}

.borde-usuario-3 {
  /* box-shadow: 0px 0 0 1px rgb(221, 169, 0); */
  background-color: #ffb711;
}

.borde-usuario-4 {
  /* box-shadow: 0px 0 0 1px rgb(13, 151, 0); */
  background-color: #2de786;
}

.borde-usuario-5 {
  /* box-shadow: 0px 0 0 1px rgb(13, 151, 0); */
  background-color: #ff4242;
}

.borde-usuario-1000000 {
  /* box-shadow: 0px 0 0 1px rgb(13, 151, 0); */
  background-color: rgb(209, 209, 209);
}

.cont-ico-usuario {
  overflow: hidden;
  padding: 0px;
  padding-top: 7px;
  border-radius: 50%;
  /* background-color: var(--color-fondo-input); */
  width: 50px;
  height: 50px;
}

.usuario-textos {
  display: flex;
  flex-direction: column;
  gap: 3px;
  overflow: hidden;
}

.usuario-textos>p {
  white-space: nowrap;
  overflow: hidden;
}

.usuario-textos>p:first-child {
  font-size: 1.1rem;
}

.usuario-textos>p:nth-child(2) {
  font-size: 0.9rem;
  color: var(--color-texto-lowest);
}

.usuario-estado {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 5px;
}

.usuario-estado>p {
  font-size: 0.85rem;
  /* color: var(--color-texto-low); */
  /* font-weight: bold; */
}