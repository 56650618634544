.ico-fecha {
  content: url(../media/ico/calendar.svg);
}

.ico-balanza {
  content: url(../media/ico/scale.svg);
}

.ico-desplegar {
  content: url(../media/ico/expandir.svg);
}

.ico-lista {
  content: url(../media/ico/lista.svg);
}

.ico-lista-color {
  content: url(../media/ico/lista_color.svg);
}

.ico-ciudad {
  content: url(../media/ico/tierra.svg);
}

/* Iconos de estado */
.ico-aprobado {
  content: url(../media/ico/check.svg);
}

.ico-exito {
  content: url(../media/ico/check.svg);
}

.ico-check {
  content: url(../media/ico/checkmark.svg);
}

.ico-error {
  content: url(../media/ico/error.svg);
}

.ico-advertencia {
  content: url(../media/ico/advertencia.svg);
}

/* Fin iconos de estado */

/* iconos de acción */
.ico-lapiz {
  content: url(../media/ico/lapiz.svg);
}

.ico-documento {
  content: url(../media/ico/documento.svg);
}

.ico-documento-color {
  content: url(../media/ico/documento_color.svg);
}

.ico-estrella {
  content: url(../media/ico/estrella.svg);
}

.ico-estrella-amarilla {
  content: url(../media/ico/estrellaAmarilla.svg);

}

/* iconos de acción */

.ico-casa {
  content: url(../media/ico/casa.svg);
}

.ico-telefono {
  content: url(../media/ico/phone.svg);
}

.ico-celular {
  content: url(../media/ico/celular.svg);
}

.ico-ruc {
  content: url(../media/ico/ruc.svg);
}

.ico-fecha {
  content: url(../media/ico/calendar.svg);
}

.ico-lupa {
  content: url(../media/ico/lupa.svg);
}

.ico-empresa {
  content: url(../media/ico/edificio.svg);
}

.ico-email {
  content: url(../media/ico/email.svg);
}

.ico-eliminar {
  content: url(../media/ico/basurero.svg);
}

.ico-guardar {
  content: url(../media/ico/guardar.svg);
}

.ico-anadir {
  content: url(../media/ico/plus.svg);
}

.ico-cerrar {
  content: url(../media/ico/close.svg);
}

.ico-tecnico {
  content: url(../media/ico/tecnico.svg);
}

.ico-descargar {
  content: url(../media/ico/download.svg);
}

.ico-cargar {
  content: url(../media/ico/upload.svg);
}

.ico-filtro {
  content: url(../media/ico/embudo.svg);
}

.ico-atras {
  content: url(../media/ico/flechaAbajo.svg);
  transform: rotate(90deg);
}

.ico-adelante {
  content: url(../media/ico/flechaAbajo.svg);
  transform: rotate(-90deg);
}

.ico-atras-simple {
  content: url(../media/ico/chevron.svg);
  transform: rotate(-90deg);
}

.ico-adelante-simple {
  content: url(../media/ico/chevron.svg);
  transform: rotate(90deg);
}

.ico-arriba-simple {
  content: url(../media/ico/chevron.svg);
}

.ico-abajo-simple {
  content: url(../media/ico/chevron.svg);
  transform: rotate(180deg);
}

.ico-reloj {
  content: url(../media/ico/reloj.svg);
}

.ico-marcas {
  content: url(../media/ico/tag.svg);
}

.ico-marcas-color {
  content: url(../media/ico/tag_color.svg);
}

.ico-candado {
  content: url(../media/ico/candado.svg);
}

.ico-engranaje {
  content: url(../media/ico/engranaje.svg);
}

.ico-termometro {
  content: url(../media/ico/termometro.svg);
}

.ico-peso-patron {
  content: url(../media/ico/pesa.svg);
}

.ico-peso-patron-color {
  content: url(../media/ico/pesa_color.svg);
}

.ico-login {
  content: url(../media/ico/login.svg)
}

.ico-cerrar-sesion {
  content: url(../media/ico/flechaAbajo.svg);
  transform: rotate(-90deg);
}

.ico-usuarios {
  content: url(../media/ico/users_color.svg);
}

.ico-usuario {
  content: url(../media/ico/usuario.svg);
}

.ico-campana {
  content: url(../media/ico/campana.svg);
}

.ico-bandeja {
  content: url(../media/ico/bandeja.svg);
}

.ico-estado-activo {
  border-radius: 50%;
  background-color: rgb(31, 206, 54);
}

.ico-estado-inactivo {
  border-radius: 50%;
  background-color: var(--eliminar);
}

.ico-cambiar {
  content: url(../media/ico/repeat.svg);
}

.ico-copiar {
  content: url(../media/ico/copiar.svg);
}

.ico-flecha-desplegar {
  content: url(../media/ico/flechaDesplegar.svg);
}

.ico-confirmar {
  content: url(../media/ico/confirmar.svg);
}

.ico-rechazar {
  content: url(../media/ico/rechazar.svg);
}

.ico-restaurar {
  content: url(../media/ico/restaurar.svg);
}

.ico-huella {
  content: url(../media/ico/huella.svg);
}

.ico-escudo {
  content: url(../media/ico/escudo.svg);
}

.ico-velocimetro {
  content: url(../media/ico/velocimetro.svg);
}

.ico-lazo {
  content: url(../media/ico/lazo.svg);
}

.ico-chart {
  content: url(../media/ico/chart.svg);
}

.ico-capas {
  content: url(../media/ico/capas.svg);
}

.ico-condicionesambientales {
  content: url(../media/ico/condicionesambientales.svg);
}

.ico-condicionesambientales-color {
  content: url(../media/ico/condicionesambientales_color.svg);
}

.ico-bookmark {
  content: url(../media/ico/bookmark.svg);
}

.ico-ayuda {
  content: url(../media/ico/ayuda.svg);
}

.ico-excel {
  content: url(../media/ico/excel.svg);
}

/* formatos */

.format-ico-form {
  width: 17px;
  max-height: 17px;
  filter: invert(60%);
  margin: 0 10px;
}

.format-ico-form-validacion {
  width: 17px;
  max-height: 17px;
  margin: 0 10px;
}

.format-ico-busqueda {
  height: 20px;
  margin-right: 5px;
  margin-left: 5px;
}

.format-ico-btn {
  width: 20px;
  max-height: 20px;
  -webkit-filter: invert(33%) sepia(75%) saturate(1751%) hue-rotate(200deg) brightness(79%) contrast(92%);
  /* -webkit-filter: invert(1) brightness(0.4) sepia(1) hue-rotate(185deg) */
  /* saturate(4); */
  /* filter: invert(1) brightness(0.4) sepia(1) hue-rotate(185deg) saturate(4); */
  filter: invert(33%) sepia(75%) saturate(1751%) hue-rotate(200deg) brightness(79%) contrast(92%);
}

.format-ico-btn-rojo {
  width: 20px;
  max-height: 20px;
  -webkit-filter: invert(1) brightness(0.4) sepia(1) hue-rotate(320deg) saturate(4);
  filter: invert(1) brightness(0.4) sepia(1) hue-rotate(320deg) saturate(4);
}

.format-ico-app {
  width: 70px;
}

.format-ico-titulo-form {
  padding: 3px;
  filter: invert(1);
  background-color: rgb(220, 100, 100);
  border-radius: 6px;
}

.format-ico-tarjeta {
  height: 15px;
}

.format-ico-accion,
.format-ico-accion-nofilter {
  height: 30px;
}

.format-ico-accion {
  /* filter: invert(0.3); */
  opacity: 0.1;
}

.cont-ico-usuario-card,
.cont-ico-usuario-card-config {
  font-size: 1.7em;
  border-radius: 50%;
  /* background-color: rgb(255, 255, 255); */
  width: 38px;
  height: 38px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
  align-self: center;
  /* box-shadow: var(--borde-input); */
  background-color: rgb(227, 238, 245);
}

.cont-ico-usuario-card-config {
  box-shadow: none;
}

.format-ico-usuario-card {
  margin-top: 5px;
  height: 38px;
  filter: invert(1);
}

.format-ico-usuario-config {
  margin-top: 5px;
  height: 38px;
  filter: invert(0.95);
}

.format-ico-row-table {
  height: 25px;
}

.format-ico-estado {
  height: 15px;
}

.format-ico-navbar {
  height: 25px;
  filter: invert(0.6)
}

.format-ico-seccion {
  height: 25px;
  filter: invert(0.4)
}

.format-ico-notificaciones-pendientes {
  filter: invert(33%) sepia(57%) saturate(6846%) hue-rotate(349deg) brightness(91%) contrast(82%);
}

.format-ico-cerrar {
  height: 30px;
  filter: invert(1);
  background-color: #31bebe;
  border-radius: 50%;
  padding: 5px;
}

.format-ico-tecnico {
  width: 25px;
  height: 25px;
}