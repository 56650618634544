.cont-historial-equipo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    max-height: 500px;
}

.cont-historial-equipo-equipos {
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 15px;
    height: 100%;
}