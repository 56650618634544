.contPrincipal {
  display: flex;
  flex-direction: column;
}

.empresa {
  margin-top: 10px;
  padding: 10px;
  padding-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.1rem;
  border-left: 3px solid lightgray;
  align-items: center;
  color: var(--color-principal);
}

.contBarraBusqueda {
  width: 300px;
  position: relative;
}

.contBarraBusqueda > div > div > div:first-child {
  background-color: rgb(235, 235, 235);
}

.btnBorrar {
  position: absolute;
  height: 20px;
  background-color: rgb(190, 190, 190);
  filter: invert(1);
  padding: 2px;
  border-radius: 50%;
  top: 6px;
  right: 6px;
}

.contOrdenes {
  display: grid;
  grid-template-columns: repeat(auto-fit, 310px);
  padding: 20px;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;
  border-left: 3px solid lightgray;
  margin-bottom: 40px;
}
