::-webkit-scrollbar {
    width: 10px;
    background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f100;
    border-radius: 1px 2px 3px 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(199, 199, 199);
    border-right: 0;
    /* border-left: 10px solid transparent; */
    /* border-radius: 12px; */
    background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(175, 175, 175);
    border-right: 0;
    /* border-left: 10px solid transparent; */
    background-clip: content-box;
}

.link {
    cursor: pointer;
    position: relative;
}

textarea {
    background-color: transparent;
    border: 0;
    outline: 0;
    height: 100%;
    resize: none;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* select {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
} */