.external-cont-select-multiple {
    width: 100%;
}

.cont-select-multiple {
    position: relative;
}

.cont-select-multiple-floating {
    position: fixed;
    margin-top: 3px;
    margin-left: -37px;
    width: 300px;
    background-color: white;
    border-radius: var(--radio-input);
    box-shadow: var(--sombra-popup);
    overflow: hidden;
    z-index: 10000;
}

.select-multiple-item {
    display: grid;
    grid-template-columns: 1fr 50px;
    padding: 10px;
    padding-right: 0px;
    cursor: pointer;
}

.select-multiple-item:hover {
    background-color: var(--color-sombras);
}

.select-multiple-selected-options {
    cursor: pointer;
    height: 100%;
    width: 100%;
    line-height: calc(var(--radio-input) * 4);
}

.cont-select-multiple-floating .ico-check {
    filter: invert(49%) sepia(97%) saturate(2022%) hue-rotate(159deg) brightness(92%) contrast(101%);
}

.cont-select-multiple-floating .label {
    color: var(--color-texto-low);
    text-align: center;
    padding: 5px;
    background-color: var(--color-fondo-app);
}

.format-ico-select-multiple {
    width: 17px;
    height: 17px;
    margin: 0 auto;
}