.contPrincipal {
    position: relative;
    height: 51.5px;
}

.selector {
    top: 60px;
    z-index: 10;
    position: absolute;
    width: 100%;
    display: none;
    border-radius: 10px;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 250px;
    box-shadow: var(--sombra-contenedor-sobrepuesto);
}

.selector>p {
    padding: 10px 20px;
}

.selector>p:hover {
    background-color: var(--color-principal);
    color: white;
}

.selector.active {
    display: flex;
    flex-direction: column;
}

.capturador {
    width: 100%;
    height: 100%;
    /* background-color: red; */
    position: absolute;
    top: 0;
}

.capturador.active {
    visibility: hidden;
}