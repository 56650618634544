.contModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-200px);
  transform-origin: 0% 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility var(--transicion-moderada), opacity var(--transicion-moderada), transform var(--transicion-moderada);
  background-color: var(--color-fondo-modalFS);
  backdrop-filter: blur(8px);
  padding: 15px;
  display: block;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.contModal.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.contTitle {
  display: grid;
  grid-template-columns: 1fr max-content;
  height: 30px;
}

.btnCerrar {
  width: 20px;
  height: 20px;
  background-color: var(--color-eliminar);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnCerrar:hover {
  filter: brightness(1.1);
}

.icoCerrar {
  width: 18px;
  height: 18px;
  filter: invert(1);
  opacity: 0;
  transition: opacity var(--transicion-rapida);
}

.btnCerrar:hover .icoCerrar {
  opacity: 1;
}