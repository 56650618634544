.tituloSeccion {
    color: var(--color-principal);
    margin-bottom: 3px;
}

.layout {
    display: grid;
    grid-template-columns: 200px 1fr 0.3fr;
    gap: 15px;
}

.seccion {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}

.contTecnicos {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.tecnico {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px 0;
    align-items: center;
    width: 100%;
}

.tecnico>div {
    opacity: 0.5;
}

.divisor {
    border-top: 1px solid lightgray;
    margin-top: 20px;
    margin-bottom: 10px;
}

.contBtns {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

.contEstados {
    display: flex;
    flex-direction: row;
}