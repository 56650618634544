.barraBusqueda {
  background-color: rgb(245, 245, 245);
  margin-right: 2px;
}

.contEncabezado {
  display: grid;
  grid-template-columns: 1fr 400px;
  padding-top: 2px;
}

.contAcciones {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 10px;
}