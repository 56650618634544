.cont-ico-app {
  width: 140px;
  height: 140px;
  display: grid;
  grid-template-rows: 1fr max-content;
  cursor: pointer;
  background-color: var(--color-fondo-button);
  gap: 5px;
  border-radius: 15px;
}

.cont-ico-app>div {
  justify-self: center;
}

.cont-ico-app>p {
  text-align: center;
  font-size: 0.9rem;
  line-height: 100%;
}