.contItemHistorial {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    background-color: white;
    padding: 10px;
    padding: 5px 5px 5px 10px;
    margin: 1px 0;
    border-top: 1px solid rgb(230, 230, 230);
}

.contItemHistorial>:first-child {
    display: flex;
    flex-direction: row;
}