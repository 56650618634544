  .contBtn {
    border-radius: var(--radio-input);
    outline: none;
    border: none;
    background-color: var(--color-fondo-button);
    color: var(--color-principal);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: calc(var(--radio-input) * 4);
    align-items: center;
    padding: 0 var(--padding-btn);
    justify-content: center;
  }

  .contBtn:hover {
    background-color: var(--color-principal);
    color: var(--color-texto-active);
  }

  .contBtn:hover.disabled {
    background-color: var(--fondo);
    color: var(--color-texto-lowest);
    cursor: default;
  }

  .contBtn span {
    font-size: 0.9rem;
    line-height: normal;
  }

  .disabled {
    background-color: rgb(240, 240, 240) !important;
    color: var(--color-texto-lowest) !important;
    filter: unset;
    cursor: default;
  }

  .disabled>.ico {
    filter: invert(71%) sepia(0%) saturate(33%) hue-rotate(157deg) brightness(87%) contrast(84%) !important;
    background-color: transparent !important;
  }

  .contBtn.disabled:hover {
    background-color: rgb(240, 240, 240) !important;
    color: var(--color-texto-lowest) !important;
  }

  .ico {
    height: 20px;
    width: 20px;
  }

  .paddingRight {
    padding-right: 3px;
  }

  .contBtn.PRINCIPAL {
    background-color: var(--color-principal);
  }

  .contBtn.PRINCIPAL:hover {
    background-color: var(--color-secundario);
  }

  .contBtn.ROJO {
    background-color: var(--color-eliminar);
  }

  .contBtn.ROJO:hover {
    background-color: var(--color-eliminar-hover);
  }

  .contBtn.TRULLO {
    background-color: var(--color-trullo);
  }

  .contBtn.TRULLO:hover {
    background-color: var(--color-trullo-hover);
  }

  .contBtn.BLANCO {
    background-color: white;
  }

  .contBtn.BLANCO:hover {
    background-color: rgb(245, 245, 245);
  }

  .contBtn.GRIS {
    background-color: rgb(240, 240, 240);
  }

  .contBtn.GRIS:hover {
    background-color: rgb(245, 245, 245);
  }

  .contBtn.TRANSPARENT {
    background-color: transparent;
  }

  .contBtn.TRANSPARENT:hover {
    background-color: rgb(250, 250, 250);
  }

  .ico.PRINCIPAL {
    filter: invert(28%) sepia(72%) saturate(1744%) hue-rotate(197deg) brightness(93%) contrast(89%);
  }

  .ico.ROJO {
    filter: invert(35%) sepia(52%) saturate(1351%) hue-rotate(323deg) brightness(90%) contrast(97%);
  }

  .ico.TRULLO {
    filter: invert(48%) sepia(75%) saturate(430%) hue-rotate(131deg) brightness(91%) contrast(88%);
  }

  .ico.BLANCO {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(294deg) brightness(105%) contrast(101%);
  }

  .ico.GRIS {
    filter: invert(58%) sepia(0%) saturate(1%) hue-rotate(267deg) brightness(86%) contrast(92%);
  }

  .label.PRINCIPAL {
    color: var(--color-principal);
  }

  .label.ROJO {
    color: var(--color-eliminar);
  }

  .label.TRULLO {
    color: var(--color-trullo);
  }

  .label.BLANCO {
    color: white;
  }

  .label.GRIS {
    color: gray;
  }