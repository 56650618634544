.contPrincipal {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    margin: 2px;
}

.contEditando {
    box-shadow: var(--borde-input-focus);
}

.contBtns {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 10px;
}

.contBtns>div {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.fila {
    background-color: transparent;
}

.fila>div {
    overflow: visible;
}

.contForm {
    display: grid;
    grid-template-columns: 1fr 350px;
}