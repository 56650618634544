.contFormDatosServicio {
    display: flex;
    flex-direction: column;
    gap: 10px;
    grid-column: 1/-1;
}

.contFormDatosServicio> :last-child {
    margin-top: 10px;
    width: max-content;
    align-self: center;
}