.titulo-form-nuevo-equipo {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.titulo-form-nuevo-equipo>div {
  height: 30px;
}

.color-peso-patron {
  background-color: #977272;
}

.color-termometro {
  background-color: #00abb1;
}