.contHub {
    display: none;
    grid-template-rows: max-content 1fr;
    position: absolute;
    left: 60px;
    top: 10%;
    bottom: 10px;
    width: 330px;
    z-index: 1000;
    border-radius: 20px;
    overflow: hidden;
    padding: 15px;
    background-color: rgba(250, 250, 250, 0.8);
    /* background-color: rgba(240, 240, 240, 0.5); */
    backdrop-filter: blur(50px);
    box-shadow: var(--sombra-contenedor-sobrepuesto);
    gap: 5px;
    height: 80vh;
}

.active {
    display: grid;
}

.estado {
    background-color: transparent;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    box-shadow: inset 0px 0px 1px 1px lightgray;
}

.estadoActivo {
    background-color: blue;
    box-shadow: none;
}

.contItems {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.contItems>:not(:first-child) {
    border-top: 1px solid rgb(230, 230, 230);
}

.contItem {
    padding: 15px;
    display: grid;
    /* grid-template-columns: 1fr; */
    grid-template-columns: 35px 1fr;
    gap: 15px;
    cursor: pointer;
    align-items: center;
    font-size: 0.85rem;
    /* border-radius: 10px; */
}

.contItem:hover {
    background-color: rgb(245, 245, 245);
}

.contItem:not(:last-child) {
    box-shadow: 0px 10px 20px -20px rgba(0, 0, 0, 0.5);
    ;
    /* border-bottom: 1px solid lightgray; */
}

/* .texto {
    line-height: 22px;
} */

.time {
    font-size: 0.8rem;
    color: gray;
    grid-column: 1/-1;
    width: 100%;
    text-align: right;
    margin-top: 5px;
}

.titulo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.titulo>h4 {
    color: rgb(0, 67, 161);
    opacity: 0.8;
    font-weight: 500;
}

.inicialUsuario {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: white;
    font-weight: 500;
}

.nombreUsuario {
    margin-bottom: 5px;
    font-weight: bold;
}

.mensajeCentroHub {
    background-color: transparent;
    height: 100%;
}

.texto {
    white-space: break-spaces;
}

.tn1 {
    background-color: rgb(231, 219, 42);
}

.tn2 {
    background-color: rgb(14, 156, 212);
}

.tn3 {
    background-color: rgb(0, 187, 100);
}

.tn4 {
    background-color: rgb(246, 126, 40);
}

.tn5 {
    background-color: rgb(44, 232, 201);
}

.tn6 {
    background-color: rgb(246, 126, 40);
}

.tn7 {
    background-color: rgb(0, 233, 23);
}

.tn8 {
    background-color: rgb(217, 36, 36);
}

.tn9 {
    background-color: rgb(243, 145, 145);
}