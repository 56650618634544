.cont-page-login {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.cont-login {
  display: flex;
  flex-direction: column;
  /* position: absolute; */
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  align-items: center;
  justify-items: center;
  width: 280px;
  height: max-content;
  margin: auto;
  /* box-shadow: 0 -30px 30px -35px #d3d3d3; */
  padding-top: 10px;
  /* transform: translate(-50%,-60%); */
}

.cont-login>form {
  display: grid;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  width: 100%;
}

.login-logo {
  height: 40px;
  content: url(../media/img/logoPinprexatColor.svg);
  margin: 0 auto;
}

/* .cont-login input::-webkit-outer-spin-button,.cont-login input::-webkit-inner-spin-button{
-webkit-appearance:none;
margin:0
} */

.login-btn-login {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  letter-spacing: 2px;
}

.cont-login>form>.login-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.format-ico-login-user {
  height: 75px;
  width: 75px;
  filter: invert(1);
  margin-top: 8px;
}

.cont-ico-login-user {
  height: 70px;
  width: 70px;
  overflow: hidden;
  border-radius: 50%;
  background-color: rgb(192, 192, 192);
  margin: 0 auto;
  display: flex;
  justify-content: center;
}