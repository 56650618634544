.cont-navbar {
  display: grid;
  grid-template-rows: 40px 1fr 40px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  background-color: black;
  box-shadow: 10px 10px 10px -5px lightgray;
  border-radius: 0 10px 10px 0;
  overflow: hidden;
}

.cont-logo-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  margin-top: 10px;
}

.navbar-logo {
  height: 20px;
}

.navbar-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.28rem;
  width: 50px;
  gap: 5px;
  align-items: center;
}

.navbar-list li a {
  display: flex;
  color: var(--color-letra);
  text-decoration: none;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 10px 0px;
  filter: brightness(0.8);
}

/* 
.navbar-list li a:hover {
  background-color: var(--color-foco-links);
} */

.navbar-list li a.active {
  color: var(--color-principal);
  filter: brightness(3);
}

.contador-notificaciones {
  font-size: 0.9rem;
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #d62f2f;
  color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
}

.navbar-fondo-menu-sesion {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 50px;
  width: 50px;
  border-radius: 0 0 10px 0;
}